define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const TRANSLATE_FIELD = 'BOXSLIDESHOW_Regular_Slideshow_Slides_New_Slide_Label';

    function fixSlideShowSlideTranslatableFields(docData, language) {
        if (!_.includes(coreUtils.languages, language)) {
            return;
        }

        const trans = coreUtils.translationsLoader.getTranslationAllKeys('boxSlideShow', language, {}, {useOldTranslations: true});
        if (!trans) {
            return;
        }
        _.forOwn(docData, function (dataItem) {
            if (dataItem.type === 'boxSlideShowSlide' || dataItem.type === 'StripContainerSlideShowSlide') {
                const currSlideIndex = dataItem.title ? dataItem.title.split(' ')[1] : '';
                dataItem.title = trans[TRANSLATE_FIELD] && currSlideIndex ? trans[TRANSLATE_FIELD].replace('<%= curr_slide %>', currSlideIndex) : dataItem.title;
            }
        });
    }


    /**
     * @exports utils/dataFixer/plugins/boxSlideShowDataFixer
     * @type {{exec: exec}}
     */
    const exports = {
        exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel) {
            const docData = pageJson.data.document_data;
            const isTemplate = _.get(rendererModel, 'siteInfo.documentType') === 'Template';
            if (!isTemplate) {
                return;
            }
            const language = requestModel ? coreUtils.wixUserApi.getLanguage(requestModel.cookie, currentUrl, requestModel.language) : 'en';
            fixSlideShowSlideTranslatableFields(docData, language);
        }
    };

    return exports;
});
