define(['lodash', 'coreUtils', 'skinExports'], function (_, coreUtils, skinExports) {
    'use strict';

    // TODO: This component is desktop only, so we'll fix these variables later
    let masterPageLoaded = false;//eslint-disable-line santa/no-module-state
    let masterPageThemeData = {}; //eslint-disable-line santa/no-module-state
    const menuItemsCounters = {};
    const pagesPendingMasterPage = []; //eslint-disable-line santa/no-module-state

    function isMasterPage(pageJson) {
        return !!pageJson.data.theme_data.THEME_DATA;
    }

    function saveMasterPageThemeData(pageJson) {
        masterPageThemeData = pageJson.data.theme_data;
    }

    function saveMenusItemsCounters(pageJson) {
        const documentData = pageJson.data.document_data;
        const menus = documentData.CUSTOM_MENUS && documentData.CUSTOM_MENUS.menus || []; // eslint-disable-line no-mixed-operators
        _.forEach(menus, menu => {
            const menuRef = menu.replace('#', '');
            const menuItems = documentData[menuRef] && documentData[menuRef].items || []; // eslint-disable-line no-mixed-operators
            const menuItemsCount = _.filter(menuItems, function (item) {
                const menuDataQuery = item.replace('#', '');
                return documentData[menuDataQuery] && documentData[menuDataQuery].isVisible;
            }).length;

            menuItemsCounters[menuRef] = menuItemsCount ? menuItemsCount : 1;
        });
    }

    function updateHeights(pageJson, comp, newItemHeight) {
        const props = getCompProps(pageJson, comp);

        if (!newItemHeight) {
            coreUtils.log.error('cannot find menu items');
            return;
        }

        props.menuItemHeight = newItemHeight;
    }

    function isAlreadyFixed(pageJson, comp) {
        const props = getCompProps(pageJson, comp);

        return !_.isUndefined(props.menuItemHeight);
    }

    function isFixNeeded(pageJson, comp) {
        return comp.componentType === 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu' && !isAlreadyFixed(pageJson, comp);
    }

    function getCompProps(pageJson, comp) {
        return pageJson.data.component_properties[comp.propertyQuery.replace('#', '')];
    }

    function getCompData(pageJson, comp) {
        return pageJson.data.document_data[comp.dataQuery.replace('#', '')];
    }

    function getCompStyle(pageJson, comp) {
        return pageJson.data.theme_data[comp.styleId] || masterPageThemeData[comp.styleId];
    }

    function fixVerticalMenu(pageJson, comp) {
        const data = getCompData(pageJson, comp);
        const compMenuRef = _.get(data, 'menuRef', '').replace('#', '');
        const menuItemsCount = menuItemsCounters[compMenuRef];
        const style = getCompStyle(pageJson, comp);
        if (!style) {
            coreUtils.log.error('cannot find style');
            return;
        }
        const separator = parseInt(style.style.properties.sepw || style.style.properties.separatorHeight || 0, 10);
        const currentSkinExports = skinExports[style.skin];
        const newItemHeight = coreUtils.verticalMenuCalculations.getItemHeight(comp.layout.height, separator, menuItemsCount, currentSkinExports);
        updateHeights(pageJson, comp, newItemHeight);
    }

    function fixComps(pageJson, comps) {
        _.forEach(comps, function (comp) {
            if (isFixNeeded(pageJson, comp)) {
                fixVerticalMenu(pageJson, comp);
            }

            if (comp.components) {
                fixComps(pageJson, comp.components);
            }
        });
    }

    function fixPage(pageJson) {
        const structureData = pageJson.structure;
        if (!structureData) {
            return;
        }
        if (structureData.components) {
            fixComps(pageJson, structureData.components);
        }
        if (structureData.mobileComponents) {
            fixComps(pageJson, structureData.mobileComponents);
        }
        if (structureData.children) {
            fixComps(pageJson, structureData.children);
        }
    }

    /**
     * @exports utils/dataFixer/plugins/verticalMenuFixer
     * @type {{exec: exec}}
     */
    const exports = {
        exec(pageJson) {
            // note that master page is used in patching, so we will hold any non master-page
            // fixes until we reached the master-page. There might be a difference in load order
            // between debug mode and non debug
            if (isMasterPage(pageJson)) {
                saveMasterPageThemeData(pageJson);
                saveMenusItemsCounters(pageJson);
                fixPage(pageJson);
                masterPageLoaded = true;
                while (pagesPendingMasterPage.length > 0) {
                    fixPage(pagesPendingMasterPage.pop());
                }
                return;
            }
            if (masterPageLoaded) {
                fixPage(pageJson);
            } else {
                pagesPendingMasterPage.push(pageJson);
            }
        }
    };

    return exports;
});
