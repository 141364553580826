define([
    'lodash',
    'experiment',
    'santa-data-fixer',
    'dataFixer/plugins/verticalMenuFixer',
    'dataFixer/plugins/boxSlideShowDataFixer',
    'dataFixer/plugins/contactFormDataFixer',
    'dataFixer/plugins/subscribeFormDataFixer',
    'dataFixer/plugins/inputsPlaceholderDataFixer'
], function (
    _,
    experiment,
    santaDataFixer,
    verticalMenuFixer,
    boxSlideShowDataFixer,
    contactFormDataFixer,
    subscribeFormDataFixer,
    inputsPlaceholderDataFixer) {
    'use strict';

    const api = {
        fix: (pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, pageFileNames) => {
            const experiments = _(_.get(rendererModel, 'runningExperiments', {}))
                .pickBy((val, spec) => experiment.isOpen(spec, {rendererModel}))
                .keys()
                .value();
            const clientSpecMap = _.get(rendererModel, 'clientSpecMap');
            const quickActionsMenuEnabled = _.get(rendererModel, 'siteMetaData.quickActions.configuration.quickActionsMenuEnabled', false);

            pageJson = santaDataFixer.fix({
                pageJson,
                clientSpecMap,
                urlFormatModel,
                quickActionsMenuEnabled,
                isViewerMode,
                experiments,
                pageIdsArray,
                pageId: _.get(pageFileNames, _.get(pageJson.structure, 'id', 'masterPage'))
            });

            api.clientOnlyDataFixers(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel);

            return pageJson;
        },
        clientOnlyDataFixers: (pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel) => {
            _.forEach([verticalMenuFixer, boxSlideShowDataFixer, contactFormDataFixer, subscribeFormDataFixer, inputsPlaceholderDataFixer], fixer =>
                fixer.exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel)
            );

            return pageJson;
        }
    };

    /**
     * @exports dataFixer/dataFixer
     */
    return _.assign({}, santaDataFixer, api);
});
